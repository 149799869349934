import { updateObject, updateItemInArray, createReducer } from './utility'
import ls from 'local-storage'
import { getVisitor } from './visitor'
// Initial Data

const userInitial = {
  isAuthenticating: false,
  isLoggedIn: false,
  authToken: null,
  userInfo: null,
  isUpdatingContact: false,
  lastContactUpdate: null,
  contactInfo: null,
  companyInfo: null,
}

// Actions

const ACTION = {
  authenticateWithToken: "authenticateWithToken",
  completeLogout: "completeLogout",
  authenticating: "authenticating",  
  receiveTokenInfo: "receiveAuthInfo",  
  errorAuthenticating: "errorAuthenticating",
  updatingContact: "updatingContact",
  receiveContactInfo: "receiveContactInfo",
  errorUpdatingContact: "errorUpdatingContact",
  activateCompany: "activateCompany",
}


export const getUser = (state) => {
  return state.alpha.user
}

export const getCompanies = (state) => {
  let user = getUser(state)
  if (!user.isLoggedIn || !user.contactInfo)
    return []
  if ('companies' in user.contactInfo)
    return user.contactInfo.companies
  else
    return []
}

// Async Action Creators

export const login = (callbackUrl, removeUserInfo=false, register=false) => {
  return (dispatch, getState, args) => {
    const { 
      apiKey=process.env.REACT_APP_GOAPP_API_KEY, 
      apiUrl=process.env.REACT_APP_GOAPP_API_URL 
    } = args || {}
    
    let user = getState().alpha.user
    if (user.isLoggedIn || user.isAuthenticating)
      return Promise.resolve()
      
    // Open login page
    let url = process.env.REACT_APP_ACCOUNT_URL + "/account/authorize/"
    let params = { callback: callbackUrl }
    if (apiKey == "000000000000")
      // Login as staff
      params["staff"] = 1
    else
      params['business'] = getState().alpha.business[0].data.uid
    
    // Try to login with previous identity
    if (user.userInfo && 'uid' in user.userInfo) {
      params['uid'] = user.userInfo.uid
      
      if (removeUserInfo)
        // Remove userInfo from local storage, so that we don't try to login
        // if failed
        ls.remove('_userInfo')
    }
    
    // 12 Dec 2019. Add visitor info so that we can link new user to this contact
    let visitor = getVisitor(getState())
    if (visitor && visitor.data.visitor_id)
      params['visitor'] = visitor.data.visitor_id
    
    if (register)
      params['register'] = 1
      
//    alert(JSON.stringify(params, null, 2))
      
//    let params = { callback: process.env.REACT_APP_PUBLIC_URL + "/auth_callback" }
//    let params = { callback: "http://178.128.86.10:9000/auth_callback/" }
    url = url + "?" + Object.entries(params).map(kv =>
      kv.map(encodeURIComponent).join("=")).join("&")
      
    // Redirect to url
    window.open(url, '_self')
  }
}

export const register = (callbackUrl, removeUserInfo=false) => {
  return login(callbackUrl, removeUserInfo, true)
}


export const logout = (callbackUrl) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user
    if (!user.isLoggedIn)
      return Promise.resolve()

    // Remove _userInfo to prevent auto login
    ls.remove('_userInfo')

    // Logout from authentication service.
    let url = process.env.REACT_APP_ACCOUNT_URL + "/account/logout/"
    let params = { callback: callbackUrl, business: getState().alpha.business[0].data.uid }
    
    url = url + "?" + Object.entries(params).map(kv =>
      kv.map(encodeURIComponent).join("=")).join("&")
      
    // Redirect to url
    window.open(url, '_self')    
    
//    // Reset user to initial
//    dispatch(completeLogout())
  }
}

export const authenticateWithToken = (authToken) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user
    if (user.isAuthenticating)
      return Promise.resolve()
  
    let url = process.env.REACT_APP_ACCOUNT_URL + "/auth/token-info/"
    
    let data = {
      token: authToken
    }
    
//    alert(url)
    
    dispatch(authenticating())
    
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveTokenInfo(authToken, json))
//        dispatch(fetchCart(authToken))
      })
      .catch(error => dispatch(errorAuthenticating(error)))
  }
}

export const setActiveCompany = (companyID) => {
  return (dispatch, getState) => {
    let user = getState().alpha.user
    if (!user.isLoggedIn || !user.contactInfo)
      return Promise.resolve()
      
    let companyInfo = null
    if ('companies' in user.contactInfo)
      companyInfo = user.contactInfo.companies.find(c => c['uid'] == companyID)
      
    if (!companyInfo) {
      alert("Invalid company: " + companyID.toString())
      return Promise.resolve()
    }    

    dispatch(activateCompany(companyInfo))
  }  
}

export const fetchContactInfo = () => {
  return (dispatch, getState, args) => {
    const { 
      apiKey=process.env.REACT_APP_GOAPP_API_KEY, 
      apiUrl=process.env.REACT_APP_GOAPP_API_URL 
    } = args || {}
    
    let user = getState().alpha.user
    if (user.isUpdatingContact)
      return Promise.resolve()
  
    let url = apiUrl + "/contact/api/contact/0/"
    
    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id
        
    let authorization = {}
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    let api_key = apiKey || "000000000000"    
      
    dispatch(updatingContact())
    
    return fetch(url, {
        method: 'GET',
        cache: 'no-cache',
        headers: { 
          'X-API-Key': api_key,
          ...authorization
        },
      })
      .then(response => response.json())
      .then(json => dispatch(receiveContactInfo(json)))
      .catch(error => dispatch(errorUpdatingContact(error)))
  }
}

export const updateContactInfo = (data) => {
  return (dispatch, getState, args) => {
    const { 
      apiKey=process.env.REACT_APP_GOAPP_API_KEY, 
      apiUrl=process.env.REACT_APP_GOAPP_API_URL 
    } = args || {}
    
    let url = apiUrl + "/contact/api/contact/0/"
    
    url = url + "?visitor=" + getState().alpha.visitor.data.visitor_id
        
    let authorization = {}
    let user = getState().alpha.user
    if (user.isLoggedIn)
      authorization = { 'Authorization': 'jwt ' + user.authToken }

    let api_key = apiKey || "000000000000"
      
    dispatch(updatingContact())
    
    return fetch(url, {
        method: 'PUT',
        cache: 'no-cache',
        headers: { 
          'Content-Type': 'application/json',
          'X-API-Key': api_key,
          ...authorization
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        return response.json().then(json => {
          if (response.ok)
            dispatch(receiveContactInfo(json))
          else
            dispatch(errorUpdatingContact(JSON.stringify(json, null, 2)))
        })
      })
      .catch(error => dispatch(errorUpdatingContact(error)))
  }
}

// Synchronous Action Creators

export const authenticating = () => ({
  type: ACTION.authenticating,
});

export const completeLogout = () => ({
  type: ACTION.completeLogout,
});

export const receiveTokenInfo = (authToken, tokenInfo) => ({
  type: ACTION.receiveTokenInfo,
  authToken,
  tokenInfo
});

export const errorAuthenticating = (error) => ({
  type: ACTION.errorAuthenticating,
  error
});

export const updatingContact = () => ({
  type: ACTION.updatingContact,
});

export const receiveContactInfo = (json) => {
  // Normalize
  let contactInfo = json
  if (!json.first_name || json.first_name === undefined)
    contactInfo['first_name'] = ''
  if (!json.last_name || json.last_name === undefined)
    contactInfo['last_name'] = ''

  return {
    type: ACTION.receiveContactInfo,
    contactInfo: contactInfo,
    receivedAt: Date.now()  
  }
};

export const errorUpdatingContact = (error) => ({
  type: ACTION.errorUpdatingContact,
  error
})

export const activateCompany = (companyInfo) => ({
  type: ACTION.activateCompany,
  companyInfo
})

// User reducer

function _authenticating(state, action) {
  return updateObject(state, { isAuthenticating: true })
}

function _completeLogout(state, action) {
  ls.remove('_userInfo')

  return updateObject(state, {
    isLoggedIn: false,
    authToken: null,
    tokenInfo: {}
  })
}

function _errorAuthenticating(state, action) {
  alert(action.error)
  return {
    ...state,
    isAuthenticating: false
  }
}

function _receiveTokenInfo(state, action) {
  let userInfo = action.tokenInfo
  
//  alert(JSON.stringify(userInfo, null, 2))
  
  // Store userInfo on local storage
  ls.set('_userInfo', userInfo)

  return {
    ...state,
    isAuthenticating: false,
    isLoggedIn: true,
    authToken: action.authToken,
    userInfo: userInfo,
    isStaff: process.env.REACT_APP_GOAPP_API_KEY == "000000000000" && userInfo.is_staff
  }
}

function _updatingContact(state, action) {
  return {
    ...state,
    isUpdatingContact: true
  }
}

function _receiveContactInfo(state, action) {
  return {
    ...state,
    contactInfo: action.contactInfo,
    isUpdatingContact: false,
    lastContactUpdate: action.receivedAt
  }
}

function _errorUpdatingContact(state, action) {
  alert("Error Updating Contact.")
//  alert(action.error)
  return {
    ...state,
    isUpdatingContact: false
  }
}

function _activateCompany(state, action) {
//  alert("_activeCompany: " + JSON.stringify(action.companyInfo, null, 2))
//  alert("state: " + JSON.stringify(state, null, 2))
  return {
    ...state,
    companyInfo: action.companyInfo
  }
}

const userReducer = (state=null, action) => {
  if (state == null) {
    // Get userInitial from local storage, but we're still in
    // not authenticated state, authToken is still null.
    // We'll use the info to auto authenticate to auth service
    state = userInitial
    let userInfo = ls.get('_userInfo')
    if (userInfo && userInfo !== undefined)
      state.userInfo = userInfo
  }
  
  const handlers = {
    [ACTION.authenticating]: _authenticating,
    [ACTION.errorAuthenticating]: _errorAuthenticating,
    [ACTION.receiveTokenInfo]: _receiveTokenInfo,
    [ACTION.completeLogout]: _completeLogout,
    [ACTION.updatingContact]: _updatingContact,
    [ACTION.receiveContactInfo]: _receiveContactInfo,
    [ACTION.errorUpdatingContact]: _errorUpdatingContact,
    [ACTION.activateCompany]: _activateCompany,
  }
  
  if (handlers.hasOwnProperty(action.type))
    return handlers[action.type](state, action)
  else
    return state
}

export default userReducer