import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { Section } from 'alpha-page'

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
});

const MyCustomSection = (props) => {
  const { theme, classes, variant, color_mode, item_size='medium', items } = props

  return (
    <Section { ...props } variant='Center'>
        My Custom Section
    </Section>
  )
}

export default withStyles(styles, { withTheme: true })(MyCustomSection)