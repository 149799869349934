import React, { useState, useEffect, useContext, useRef } from 'react'

import { Page as AlphaPage } from 'alpha-page'

import MyCustomSection from '../sections/MyCustomSection'

const sectionMaps = {
  MyCustomSection: MyCustomSection
}

const contentBlockMaps = {
}

const Page = (props) => {
  
  return (
    <AlphaPage 
     newSectionMaps={ sectionMaps }
     newContentBlockMaps={ contentBlockMaps } 
     { ...props } />
    )
}

export default Page