import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActions'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
  root: {
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },  
})

const CardMessage = ({ classes, theme, title, subtitle, images, buttons, onClick }) => {

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={ images[0].image_url }
        title={ title }
      />
      <CardContent>
        <Typography variant="body1" color="textSecondary" component="p">
          { title }
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          { subtitle }
        </Typography>
      </CardContent>
      
      { buttons &&
      <>
      <Divider />
      <CardActionArea>
        <Box flex={1} flexDirection='column'>
        { buttons.map(btn =>
        <Button variant='text' color='primary' fullWidth onClick={()=>onClick(btn)}>
          { btn.title }
        </Button>
        )}
        </Box>
      </CardActionArea>
      </>
      }
    </Card>
  );
};

export default withStyles(styles, { withTheme: true })(
  CardMessage
  )