import { createMuiTheme } from '@material-ui/core/styles'
import { green, grey, red } from '@material-ui/core/colors'

const appFont = process.env.REACT_APP_FONT ? JSON.parse(process.env.REACT_APP_FONT) : { 'default': []}
const appPalette = process.env.REACT_APP_PALETTE ? JSON.parse(process.env.REACT_APP_PALETTE) : {}
const pagePalette = process.env.REACT_APP_PAGE_PALETTE ? JSON.parse(process.env.REACT_APP_PAGE_PALETTE) : {}

const rawTheme = createMuiTheme({
  typography: {
    fontFamily: [
      ...appFont.default,
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')
  }
});

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    primary: 'primary' in appPalette ? { main: appPalette.primary, contrastText: '#fff' } : rawTheme.palette.primary,
    secondary: 'secondary' in appPalette ? { main: appPalette.secondary, contrastText: '#fff' } : rawTheme.palette.secondary,
  },
  sectionPalette:{
    ...rawTheme.pagePalette,
    white: 'white' in pagePalette ? { background: pagePalette.white.background, contrastText: pagePalette.white.text } : rawTheme.palette.primary,
    black: 'black' in pagePalette ? { background: pagePalette.black.background, contrastText: pagePalette.black.text } : rawTheme.palette.primary,
    light: 'light' in pagePalette ? { background: pagePalette.light.background, contrastText: pagePalette.light.text } : rawTheme.palette.primary,
    dark: 'dark' in pagePalette ? { background: pagePalette.dark.background, contrastText: pagePalette.dark.text } : rawTheme.palette.primary,
    accent: 'accent' in pagePalette ? { background: pagePalette.accent.background, contrastText: pagePalette.accent.text } : rawTheme.palette.secondary,
  },
  overrides: {
    MuiContainer: {
      maxWidthLg: {
        [rawTheme.breakpoints.up('sm')]: {
          paddingLeft: rawTheme.spacing(6),
          paddingRight: rawTheme.spacing(6),          
        },
        [rawTheme.breakpoints.up('md')]: {
          paddingLeft: rawTheme.spacing(12),
          paddingRight: rawTheme.spacing(12),
        }
      },
    },
  },
  typography: {
    ...rawTheme.typography,
    h1: {
      ...rawTheme.typography.h1,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '5rem' //'6rem'
      }
    },
    h2: {
      ...rawTheme.typography.h2,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '3rem' //'3.75rem'
      }
    },
    h3: {
      ...rawTheme.typography.h3,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '2.5rem' //'3rem'
      }
    },
    h4: {
      ...rawTheme.typography.h4,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '1.75rem' //'2.125rem'
      }
    },
    h5: {
      ...rawTheme.typography.h5,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '1.25rem' // '1.5rem'
      }
    },
    h6: {
      ...rawTheme.typography.h6,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: '1.125rem' // '1.25rem'
      }
    },
  },
};

export default theme;
