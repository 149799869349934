import { renderToString } from 'react-dom/server'
import React, { useState, useRef, useEffect, useContext, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getNewConversation, getLatestConversation, getConversation, deleteConversationIfEmpty, startNewConversation, fetchConversation, fetchMessages, sendMessage, markReadAll, startTyping, WSSTATE } from '../data/reducers/conversation'
import { getBusinessInfo, fetchBusinessInfo } from '../data/reducers/business'
import { getUser, fetchContactInfo, updateContactInfo } from '../data/reducers/user'
import { wsSend } from '../middleware/conversation'

import ReactMarkdown from 'react-markdown'

import { withStyles } from '@material-ui/core/styles'

import { getChatOnlineStatus, isChatOnline } from './Inbox.js'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import moment from 'moment'
import classNames from 'classnames'

// UI
import ChatMessage from './ChatMessage'
import CardContent from './Card'
import ReactLoading from 'react-loading'
import Reveal from 'react-reveal/Zoom'
import FormInput from './FormInput'

import { default as SlickSlider } from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Drawer from '@material-ui/core/Drawer'
import Fab from '@material-ui/core/Fab'
import SendIcon from '@material-ui/icons/Send'
import CancelIcon from '@material-ui/icons/Cancel'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import Button from '@material-ui/core/Button'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import Popover, {PopoverAnimationVertical} from '@material-ui/core/Popover'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import ResizeDetector, { withResizeDetector } from 'react-resize-detector'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  header: {
    zIndex: 1300,
    position: 'sticky',
    top: '0',
  },
  footer: {
    display: 'block',
    position: 'sticky',
    bottom: '0',
  },
  chatHeader: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing.unit * 2,
  },
  chatHeaderContent: {
    paddingLeft: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 2,    
  },
  avatar: {
    width: 50,
    height: 50,
    backgroundColor: '#ddd'
  },  
  headerTitle: {
    fontWeight: 800
  },
  headerSubTitle: {  
  },
  chatInfo: {
    color: '#999',
  },
  messageTop: {
//    minHeight: '70vh',
//    [theme.breakpoints.up('sm')]: {
//      minHeight: '80vh'
//    }

    minHeight: '50vh',
    [theme.breakpoints.up('sm')]: {
//      minHeight: '30vh'
      minHeight: '40vh'
    }
  },
  messageList: {
    width: '100%',
//    maxWidth: 500,
    padding: theme.spacing.unit * 2,
    backgroundColor: '#eee',
  },
  inline: {
    display: 'inline',
  },
  componentBar: {
    margin: 0
  },
  composeBar: {
//    marginTop: theme.spacing.unit * 2,
//    marginBottom: theme.spacing.unit * 2,
    display: 'block',
//    position: 'fixed',
//    bottom: 0
    
  },
  composeBarPaper: {
    display: 'flex',
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    margin: 'auto',
//    maxWidth: 500,
    alignItems: 'center',
  },
  filesPreviewPaper: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  composeInput: {
//    padding: `${theme.spacing.unit * 2}`,
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: 0, //theme.spacing.unit * 1.5,
    paddingTop: theme.spacing.unit * 0,
    paddingBottom: theme.spacing.unit * 0,
    width: '100%'
  },
  composeIcon: {
    padding: theme.spacing.unit
  },
  composeSendIcon: {
    padding: theme.spacing.unit,
  },
  displayNone: {
    display: 'none'
  },
  attachFileZone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginBottom: theme.spacing.unit
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  previewImageDiv: {
    width: '100%',
    borderColor: '#eeeeee',
    borderWidth: 1
  },
  muted: {
    color: '#999'
  },
  sliderPrevButton:{
    top: '50%',
    display: 'block',
    position: 'absolute',
    transform: 'translate(0, -50%)',
    left: 0, 
    zIndex: 1, 
    width: '32px', 
    height: '32px',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '50%'
  },
  sliderNextButton:{
    top: '50%',
    display: 'block',
    position: 'absolute',
    transform: 'translate(0, -50%)',
    right: 0, 
    zIndex: 1, 
    width: '32px', 
    height: '32px',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '50%'
    
  },
  iconChevron:{
    fontSize: '2rem',
    color: 'white',
  },

})

const FilePreview = ({ classes, file }) => {
  const [ image, setImage ] = useState(null)
  
  useEffect(() => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result)
    };
    reader.readAsDataURL(file);
  }, file)
  
  return (
    <div className={classes.previewImageDiv}>
      <img src={image} className={classes.previewImage}/>
    </div>
  )
}

/*
const AttachFileZone = ({ classes, onCancel, sendFiles }) => {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
  }, [])
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({onDrop})
  
  const [ files, setFiles ] = useState([])
  
  useEffect(() => {
    if (acceptedFiles)
      setFiles([...files, ...acceptedFiles])
  }, [acceptedFiles])
  
  return (
    <div>
      <div {...getRootProps()} className={ classes.attachFileZone }>
        <input {...getInputProps()} />
        { isDragActive ?
          <p>Drop the files here ...</p>
          :
          <Grid container spacing={1}>
            { files.map(file => (
              <Grid item xs={3}>              
                <FilePreview classes={classes} file={file} />
              </Grid>
              ))
            }
            <Grid item xs={3}>              
              <p>Add file</p>
            </Grid>
          </Grid>          
        }
      </div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button variant="contained" color="primary" size="medium" aria-label="" className={classes.mainButton} fullWidth onClick={()=>sendFiles(files)}>
            Kirim
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" color="secondary" size="medium" aria-label="" className={classes.mainButton} fullWidth onClick={onCancel}>
            Batal
          </Button>
        </Grid>
      </Grid>
      
    </div>
  )
}
*/

const ComposeBar = ({ classes, sendMessage, defaultValue, disabled, onChange }) => {

  const [message, setMessage] = useState({body: ""})
  
  const [files, setFiles] = useState([])
  
  const handleChange = name => event => {
    setMessage({ ...message, [name]: event.target.value });

    if (onChange)
      onChange(message)
  } 
  
  const handleKeyDown = event => {
    if (event.keyCode == 13)
      send()
  }
  
  const send = () => {
    sendMessage(message.body, files)
    clear()
  }
  
  const clear = () => {
    setMessage({ ...message, body: "" })
    setFiles([])
  }
  
  useEffect(() => {
    if (defaultValue)
      setMessage({
        ...message,
        body: defaultValue
      })
  }, [defaultValue])
  
  const addFiles = ({ target }) => {
    let newFiles = []
    for (let i = 0; i < target.files.length; i++) {
      let file = target.files[i]
      newFiles.push(file)
    }
    
    setFiles([...files, ...newFiles])
  }
  
  return (
    <Box className={classes.composeBar}>
      { files.length ?
        <Paper className={classes.filesPreviewPaper} elevation={0}>
          <Grid container spacing={1}>
            { files.map(file => (
              <Grid item xs={3}>              
                <FilePreview classes={classes} file={file} />
              </Grid>
              ))
            }
          </Grid>
        </Paper>
        :
        <></>
      }
      <Paper className={classes.composeBarPaper} elevation={0}>      
        <Input placeholder="Tulis pesan anda" className={classes.composeInput} margin="normal" variant="filled" disableUnderline={true} value={message.body} onChange={handleChange("body")} onKeyDown={handleKeyDown}
          disabled={disabled} />
        { !disabled &&
        <>
          <input
            accept="image/*"
            className={classes.input}
            style={{ display: 'none' }}
            id="attachFileChat"
            multiple
            onChange={addFiles}
            type="file"
            disable={disabled}
          />
          <label htmlFor="attachFileChat">
            <IconButton size="medium" 
               component="span"
              className={ classes.composeSendIcon }
              >
              <AddAPhotoIcon />
            </IconButton>        
          </label>           
          <IconButton size="medium" 
            className={ classNames(classes.composeSendIcon, { [classes.displayNone]: !message.body && !(files && files.length) })} 
              onClick={() => send() }
            >
            <SendIcon />
          </IconButton>
        </>
        }
      </Paper>
    </Box>  
  )
}

const OptionInputBar = ({ theme, classes, sendMessage, helperText, options }) => {

  let smUp = useMediaQuery(theme.breakpoints.up('sm'))
  
  return (
    <Box className={classes.composeBar}>
      <Paper className={classes.composeBarPaper} elevation={0}>
        
        { smUp ?
        
        <Box display='flex' flexDirection='row' alignItems='flex-end' width='100%' flexWrap='wrap' ml={1} mr={1}>
          <Box mb={1} width='100%'>
            <Typography variant='body2' className={classes.muted}>
              { helperText }
            </Typography>
          </Box>
        
          { options.map(option => (
            <Box mt={1} mr={1}>
              <Button variant="outlined" color="primary"
                style={{ textTransform: 'none', fontSize: theme.typography.body1.fontSize}}
                onClick={ () => sendMessage(JSON.stringify({ value: option.value, text: option.text })) }>{ option.text }</Button>
            </Box>
            ))
          }
        </Box>
        
        :
        
        <Box display='flex' flexDirection='column' alignItems='center' width='100%' ml={1} mr={1}>
          <Box mb={1}>
            <Typography variant='body2' className={classes.muted}>
              { helperText }
            </Typography>
          </Box>
        
          { options.map(option => (
            <Box mt={1} flexGrow={1} width='100%'>
              <Button variant="outlined" color="primary" fullWidth
                style={{ textTransform: 'none', fontSize: theme.typography.body1.fontSize }}
                onClick={ () => sendMessage(JSON.stringify({ value: option.value, text: option.text })) }>{ option.text }</Button>
            </Box>
            ))
          }
        </Box>                
        }
      </Paper>
    </Box>  
  )
}

const InlineOptionInputBar = ({ theme, classes, sendMessage, helperText, options, animated }) => {

  let smUp = useMediaQuery(theme.breakpoints.up('sm'))
  
  return (
    <Box mt={1} ml={3}>
        <Box display='flex' flexDirection='row' justifyContent='flex-end' width='100%' flexWrap='wrap' ml={1} mr={1}>
          { options.map(option => (
            <Box mt={1} mr={1}>
              <Reveal duration={animated ? 500 : 0}>            
              <Button variant="outlined" color="primary"
                style={{ textTransform: 'none', fontSize: theme.typography.body1.fontSize, backgroundColor: 'white'}}
                onClick={ () => sendMessage(JSON.stringify({ value: option.value, text: option.text })) }>{ option.text }</Button>
              </Reveal>
            </Box>
            ))
          }
        </Box>
    </Box>  
  )
}

const FormInputBar = (props) => {
  
  return (
    <Box mt={1} ml={3}>
      <Box display='flex' flexDirection='row' justifyContent='flex-end' width='100%' flexWrap='wrap' ml={1} mr={1}>
        <FormInput {...props}/>          
      </Box>
    </Box>  
  )
}

const ContactFormAction = ({ classes, user, updateContactInfo }) => {

  const contactForm = (
    <ContactForm initial={user.contactInfo}
      inline={ true }
      saveForm={ (contact) => {
        updateContactInfo(contact)
      }}
     />
  )
  
  return (
    <Box className={classes.componentBar}>
      { contactForm }
    </Box>  
  )
}

const ChatHeader = ({ classes, business, conversation, openChat, wsState }) => {

  // When displayed inside popup, we need to get popup width, so that
  // the header div can have fixed position and width.
  // On mobile, we can just use 100%, but not popup is not full width.

  let headerWidth = '100%'
  
  let headerStyle = {    
//    display: 'block',
//    position: 'fixed',
//    top: '0'
    width: headerWidth
  }    

  let title = business.name
    title = conversation.data.parties.map(p => {
      if (p.agent) {
        let name = p.agent.first_name
        if (p.agent.last_name && p.agent.last_name !== undefined)
          name += " " + p.agent.last_name
        if (p.agent.is_service_user)
          name += "" //" (Bot)"
        else
          name += " (Agent)"
//          name += " (" + business.name + ")"
        return name
      }
      else
        return null
    }).reduce((p, n) => {
      if (p && n)
        return p + ", " + n
      else if (n)
        return n
      else
        return p
    })
    
    if (!title && conversation.data.queue)
      title = conversation.data.queue.name    
    
    if (!title || title.length <= 0)
      title = "You"
      
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const showMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const hideMenu = () => {
    setMenuAnchorEl(null);
  };


  return (
  
  <div className={classes.chatHeader} style={headerStyle}>  
    <Box container className={classes.chatHeaderContent} display='flex' direction='row'>    
      <Box mr={1}>
        <Avatar src={ business.avatar == undefined ? null : business.avatar.image_url } className={classes.avatar} border={1}/>      
      </Box>
      <Box flexGrow={1}>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Typography className={classes.headerTitle} variant="body1" align="left" >
            { business.name }
          </Typography>

          { false && 
          <>
          <Typography className={classes.headerTitle} variant="body1" align="left" onClick={showMenu}>
            { business.name }
          </Typography>
          <ExpandMoreIcon />
          </>
          }
        </Box>
        <Typography className={classes.headerSubTitle} variant="body2" align="left">
          { title }
        </Typography>  
      </Box>
    </Box>
    <Divider />
    <Menu
        id={menuAnchorEl ? 'chat-menu-popover' : undefined}
        open={ Boolean(menuAnchorEl) }
        anchorEl={menuAnchorEl}
        onClose={hideMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}        
      >
      <MenuItem onClick={ () => openChat(0) }>
        <AddIcon /><Box mr={1} />Start New Chat
      </MenuItem>
    </Menu>
    { wsState != WSSTATE.Connected && 
    <Box style={{ position: 'absolute', top: '0', width: headerWidth, textAlign: 'center' }}>
      <Typography style={{ fontSize: '.9em', backgroundColor: '#f00', color: '#fff' }}>
        { wsState }
      </Typography>
    </Box>
    }
  </div>
  )
}

const MessageItem = ({ classes, theme, user, item, showResponseTemplate=false, openLink, sendMessage, businessAvatar }) => {

  let sender = ""
  let messages = []
  let date = ""
  let side = "left"
  let avatar = null
  let alternate = false
  
  if (item.isSending) {
    sender = "You"
    side = "right"
  }
  else if (user && user.isStaff) {
    if (item.data.sender.agent && user && user.isStaff && item.data.sender.agent.uid == user.userInfo.uid) {
      sender = "You"
      side = "right"
    }
    else if (item.data.sender.agent) {
      sender = item.data.sender.agent.first_name
      if (item.data.sender.agent.last_name)
        sender += " " + item.data.sender.agent.last_name
      if (user.isStaff) {
        // Another Staff
        side = "right"
        alternate = true
      }
    }
    else {
      sender = item.data.sender.contact.first_name
      if (item.data.sender.contact.last_name)
        sender += " " + item.data.sender.contact.last_name
      side = "left"
    }
  }
  else {
    if (item.data.sender.agent) {
      sender = item.data.sender.agent.first_name
      if (item.data.sender.agent.last_name)
        sender += " " + item.data.sender.agent.last_name
      side = "left"
    }
    else {
      sender = "You"
      side = "right"
    }
  }

/*
  if (!item.isSending && item.data.sender.agent) {
    sender = item.data.sender.agent.first_name
    side = "left"
    if (businessAvatar)
      avatar = businessAvatar
  }
*/
  
  let animated = item.data.is_new ? true : false
  
  const onCardButtonClick = (item) => (button) => {
    if (button.button_type && button.button_type == 'callback') {
      let data = {
        ...button
        }
      // alert(JSON.stringify(data, null, 2))
      sendMessage(JSON.stringify(data))
    }
    else if (button.link_to)  
      openLink(button.link_to)
  }

  let actionComponent = null
  let cardComponents = null
  
  const renderers = {
    paragraph: (props) => <Typography gutterBottom className={ classes.paragraph } variant='body2'> { props.children } </Typography>, 
  }  
  
  let nextItem = item
  while (nextItem) {
    let body = nextItem.data.body      
    if (body.startsWith("{") && body.endsWith("}")) {
      let data = JSON.parse(body)
      body = data['text']
    }

    if (messages.length == 0) {
      messages.push(
        <div>
          <Typography variant='body2' style={{ fontWeight: 800, opacity: 0.3 }}>{sender}</Typography>
          <ReactMarkdown source={body} renderers={renderers}/>
        </div>
        )      
    }
    else
      messages.push(<ReactMarkdown source={body} renderers={renderers}/>)
    
    if (nextItem.data.attachments && nextItem.data.attachments !== undefined && nextItem.data.attachments.length > 0) {
    
      messages.push(
        ...nextItem.data.attachments.map(att => (
            <img src={att.image_url} className={classes.previewImage}/>
        )))
    }

    if (!nextItem.next)
      break
    nextItem = nextItem.next    
  }

  if (nextItem.data.body && nextItem.data.body != undefined) {
    let body = nextItem.data.body

    if (body.startsWith("{") && body.endsWith("}")) {
      let data = JSON.parse(body)
      
      let cardData = data['card']
      if (cardData) {
        cardComponents = [(
          <CardContent title={cardData['title']} subtitle={cardData['subtitle']} images={cardData['images']} buttons={cardData['buttons']} onClick={ onCardButtonClick(cardData) }/>
        )]
      }
      let cardListData = data['card_list']
      if (cardListData) {
        cardComponents = cardListData.map(item => (
          <CardContent title={item['title']} subtitle={item['subtitle']} images={item['images']} buttons={item['buttons']} onClick={ onCardButtonClick(item) }/>
          ))
      }   
      
      if (showResponseTemplate) {
        let inputType = data['input_type']
        if (inputType == 'option') {
          actionComponent = (
            <InlineOptionInputBar theme={theme} classes={classes} options={data['options']} helperText={data['helper_text']} sendMessage={sendMessage} animated={animated} />
            )
        }
        else if (inputType == 'form') {
          actionComponent = (
            <FormInputBar
              formFields={data['form_fields']} 
              saveForm={(values)=> {
                let text = data['form_fields'].map((ff) => {
                    let val = values[ff.field_name]
                    if (!val)
                      val = ""
                    else if (ff.input_type == 'option' || ff.input_type == 'radio')
                      val = ff.options.find(o => o.value == val).text
                    return `${ff.label}: ${val}`
                  }).reduce((p, n) => ( p + ", " + n ))
                
                sendMessage(JSON.stringify({ form_values: values, text: text })    )
              }}
            />
          )
        }
      }
    }
    
//    alert(renderToString(<ReactMarkdown source={body} renderers={renderers}/>))
    
//    let body = item.data.body.replace(/(\[.*?\]\()(.+?)(\))/g, "<a href='$2'>$1</a>")
//    messages.push(<>{ body }</>)
  }
      
  if (nextItem.isSending)
    date = "Sending..."
  else if (nextItem.data.sent_at) {
    date = moment(nextItem.data.sent_at).fromNow(true)
    
    if (nextItem.data.sender && nextItem.data.sender.contact) {
      if (nextItem.data.read_at !== undefined && nextItem.data.read_at)
        date += " . read"
      else
        date += " . not read"
    }
  }
  
  function SliderPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Box 
        className={ classes.sliderPrevButton }
        onClick={onClick}>
        <ChevronLeftIcon className={classes.iconChevron}/>
      </Box>
    );
  }
  
  function SliderNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Box
        className={ classes.sliderNextButton }
        onClick={onClick}>
        <ChevronRightIcon className={classes.iconChevron}/>
      </Box>
    );
  }

  return (
    <>
      <ChatMessage
        side={side}
        alternate={alternate}
        avatar={null}
        messages={messages}
        status={ date }
        animated={ animated }
      />
      
      { cardComponents &&
        false &&
        <Box mt={2} mb={2}>
        
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={cardComponents.length}
          >
            <Slider>
            { cardComponents.map((card, index) => (
              <Slide index={index}>
                  { card }
              </Slide>
              ))
            }
            </Slider>
              <ButtonNext className={classes.chevronNext}>
                <ChevronRightIcon className={classes.iconChevron}/>
              </ButtonNext>  
            <Box className={classes.chevronPrev}>
              <ButtonBack>
                <ChevronLeftIcon className={classes.iconChevron}/>
              </ButtonBack>
            </Box>
        
          </CarouselProvider>
       </Box>
      }
      
      { cardComponents &&
        false &&
        <Box mt={2} mb={2}>        
          <Carousel
            itemWidth={240}
            centered
            arrowLeft={<Box className={classes.chevronPrev}><ChevronLeftIcon className={classes.iconChevron}/></Box>}
            arrowRight={<Box className={classes.chevronNext}><ChevronRightIcon className={classes.iconChevron}/></Box>}
            addArrowClickHandler
          >
            { cardComponents.map((card, index) => (
              <div style={{ margin: 8 }}>
                  { card }
              </div>
              ))
            }
          </Carousel>
       </Box>
      }      
      
      { cardComponents &&
        true &&
        <Box mt={2} mb={2}>        
          <SlickSlider
            className="center"
            arrows={true}
            slidesToShow={1.3}
            infinite={false}
            prevArrow={ <SliderPrevArrow/> }
            nextArrow={ <SliderNextArrow/> }
          >
            { cardComponents.map((card, index) => (
            <div>
              <Box style={{ padding: '0px 8px' }}>
                  { card }
              </Box>
            </div>
              ))
            }
          </SlickSlider>
       </Box>
      }      
            
      { actionComponent &&
        <>
        { actionComponent }
        </>
      }      
    </>
  )
}


const MessageList = ({ classes, theme, business, user, messages, parties, initialMessage, footerMessage, openLink, sendMessage, containerHeight=0 }) => {

  const bottomDiv = useRef()
//  const listDiv = useRef()

  const [ topPadding, setTopPadding ] = useState(-1)
  
  const scrollToBottom = () => {
//    alert("Scroll to bottom")
//		listDiv.current.scrollTop = listDiv.current.scrollHeight;
    //bottomDiv.current.scrollIntoView({ behavior: "smooth" })
    // Do not smooth scroll on first load
    bottomDiv.current.scrollIntoView()
	}
	
	// Scroll to bottom when message is added or typing parties changed
	let messagesLength = messages.length + (parties.filter(item => item['typing_at'] ? true : false).length << 8) + (footerMessage ? 1 : 0)
  
  useEffect(() => {
    scrollToBottom()
  }, 
  [messagesLength, topPadding]
  )
  
  let groupedMessages = []
  if (messages.length > 0) {
    groupedMessages = messages.reduce((p, n) => {      
      let body = n.data.body    
      let hasComponent = false
      if (body.startsWith("{") && body.endsWith("}")) {
        let data = JSON.parse(body)
        if (data['card_list'] || data['card'] || data['input_type'])
          hasComponent = true
      }
      n.hasComponent = hasComponent
      n.next = null // reset
      
      if (p.length > 0 && !n.isSending) {
        let lastItem = p[p.length-1]
        while (lastItem.next)
          lastItem = lastItem.next
        let sent_interval = moment(n.data.sent_at).diff(moment(lastItem.data.sent_at))
        if (!lastItem.isSending) {
          if ((n.data.sender.agent && lastItem.data.sender.agent && n.data.sender.agent.uid == lastItem.data.sender.agent.uid) ||
              (n.data.sender.contact && lastItem.data.sender.contact)) {
            if (!lastItem.hasComponent && sent_interval <= 30000) {  
              lastItem.next = n
              return p
            }
          }
        }
      }
    
      return [...p, n]
    }, [])
  }
  
  let businessAvatar = business.avatar == undefined ? null : business.avatar.image_url

  let typing_at = null
  parties.forEach(p => {
    if (p.agent && p.typing_at)
      typing_at = moment(p.typing_at).toDate()
  })

  const [typingTimeout, setTypingTimeout] = useState()

  useEffect(() => {
    if (typing_at && !typingTimeout) {
      const interval = new Date().getTime() - typing_at.getTime()
      if (interval < 10000) {
        if (typingTimeout)
          clearTimeout(typingTimeout)
        setTypingTimeout(setTimeout(() => {
          //alert('timeout')
          setTypingTimeout(null)
        }, 10000 - interval))
      }
    }
  }, [typing_at])
  
  return (
    <div className={classes.messageList}>    
      <div className={classes.messageTop} style={{ minHeight: containerHeight-40 }} />
      <Typography variant='body1' className={classes.chatInfo} gutterBottom>
      
      { initialMessage }
      
      </Typography>

      <div>
            { groupedMessages.length > 0 &&
              groupedMessages.map((item, index) =>
                  <MessageItem classes={classes} theme={theme} item={item} user={user}
                   showResponseTemplate={index == (groupedMessages.length-1) ? true : false}
                   sendMessage={sendMessage} openLink={openLink} businessAvatar={businessAvatar}
                   />
              )
            }
            
            { parties.map(item =>
              (item.agent && item['typing_at'] && (new Date() - moment(item['typing_at']).toDate()) < 10000) ?
                <ChatMessage
                  side='left'
                  avatar={businessAvatar}
                  messages={[
                    (<Box style={{ height: 32, position: 'relative', top: -16 }}>
                        <ReactLoading type='bubbles' color='#555' width={64} height={64} />
                    </Box>
                    )
                  ]}
                />
              :
                <></>
              )
            }
      </div>
      { footerMessage &&
        <Typography variant='body2' 
          className={classes.chatInfo}
          style={{ 
            padding: theme.spacing(2),
            textAlign: 'center'
          }}>      
          { footerMessage }
        </Typography>
      }
      
      <div ref={bottomDiv} />
    </div>
  )
}

const ChatMessagesView = ({ height, theme, classes, history, headerComponent=ChatHeader, renderHeader, business, user, conversationKey, conversation, fetchMessages, sendMessage, markReadAll, startTyping, deleteConversationIfEmpty, fetchContactInfo, updateContactInfo, openChat, onHide, wsState }) => {

  useEffect(() => {
//    const intervalID = setInterval(() => {
//      fetchConversation()
//      }, 2000)
      
    return () => {
      // Delete conversation if it's empty.
      deleteConversationIfEmpty()
    }
  }, [])

  useEffect(() => {
    let timeoutID = null
    if (conversation.data.new_count > 0) {
      timeoutID = setTimeout(() => {
        markReadAll()
      }, 50)
    
    }
    
    return () => {
      if (timeoutID)
        clearTimeout(timeoutID)    
    }
  }, [conversation.data.new_count])
  
  const [firstFetch, setFirstFetch] = useState(true)
  
  useEffect(() => {
    // Refetch on first open or when connected
    if (wsState == WSSTATE.Connected)
      fetchMessages()
  }, [wsState, conversationKey])
  
  const checkOnlineAndSendMessage = (message, files=null) => {
    if (wsState != WSSTATE.Connected) {
      alert("Can not send message because you are not online. Please check your internet connection and try again!")
      return
    }
    
    sendMessage(message, files)
  }
  
  const openLink = (linkTo) => {
    if (linkTo.startsWith("mailto:")) {
      window.location.href = linkTo
      return
    }

    let link = ""
    if (linkTo.startsWith("/"))
      link = linkTo
    else
      link = "/" + linkTo

    if (link == "/home")
      link = "/"

    history.push(link)

    onHide()
  }
  
  let typing_at = null
  conversation.data.parties.forEach(p => {
    if (p.contact && p.typing_at)
      typing_at = moment(p.typing_at).toDate()
  })

  // Check last message

  let component = null
  let defaultValue = null
  let footerMessage = null
  
  if (conversation.data.closed_at)
    footerMessage = "This chat has been ended."
    
  if (conversation.data.accept_reply) {
    let disableSendMessage = false

    if (conversation.data.closed_at)
      disableSendMessage = true
    
    if (conversation.messages.length) {
      let lastMessage = conversation.messages[conversation.messages.length-1]
      let body = lastMessage.data.body
      if (body.startsWith("{") && body.endsWith("}")) {
        let data = JSON.parse(body)
        let inputType = data['input_type']
        if ('default' in data)
            defaultValue = data['default']
        if (inputType == 'option') {
          component = (
            <OptionInputBar theme={theme} classes={classes} options={data['options']} helperText={data['helper_text']} sendMessage={checkOnlineAndSendMessage} />
            )
          // Moved to message list
          component = null
        }
        
        if (data['disable_send_message'])
          disableSendMessage = true
      }
    }
    
    if (!component)
      component = (
        <ComposeBar theme={theme} classes={classes} sendMessage={checkOnlineAndSendMessage} defaultValue={defaultValue} 
          disabled={disableSendMessage}
          onChange={() => {
            if (!typing_at || (new Date().getTime() - typing_at.getTime()) >= 5000)
              startTyping()
          }}
        />
      )
  }

  let header = null
  if (renderHeader)
    header = renderHeader(conversation)
  else if (headerComponent)
    header = React.createElement(headerComponent, {
      theme, classes, business, conversation, openChat, wsState
      })

  return (
    <div className={classes.root}>
      { header &&
      <div className={classes.header}>
        { header }
      </div>
      }

      <MessageList
        classes={classes} theme={theme} business={business}
        user={user}
        messages={conversation.messages}
        parties={conversation.data.parties}
        sendMessage={checkOnlineAndSendMessage}
        openLink={openLink}
        containerHeight={height}
        footerMessage={footerMessage}
        />
      <Divider />
      <div className={classes.footer}>
      { component }
      </div>
    </div>
    )
}

const NewChatView = ({ classes, business, openChat, isStarting, conversationKey, conversation, startNewConversation, wsStart, wsState }) => {
  const [hasStarted, setHasStarted] = React.useState(false);
  
  
  // Initialize new conversation.    
  useEffect(() => {
    if (conversationKey == 0) {
      if (conversation && hasStarted) {
        // Send start command to websocket
        wsStart()
        
        // Reopen chat with new conversation key
        openChat(conversation.key)
      }
      else {
        // Try to start a new convrsation
        startNewConversation()
        setHasStarted(true)
      }
    }  
    else if (conversationKey == -1) {
      if (conversation)
        openChat(conversation.key)
      else
        openChat(0)
    }
  }, [conversation, conversationKey])
  
  
  return (
    <div className={classes.root} style={{ minHeight: 100 }}>
      <Box display='flex' justifyContent='center' alignItem='center'>
      { isStarting ?
      <Typography variant='body1'>
      Starting...
      </Typography>
      :
      <Typography variant='body1'>
      Please wait...
      </Typography>
      }
      </Box>
    </div>
  )
}

export const ChatView = (props) => {
  const { conversationKey } = props
  
  // This can be null when loading chat before conversation list fetched
  if (conversationKey > 0 && !props.conversation)
      return <>&nbsp;</>
  
  if (conversationKey <= 0)
    return (<NewChatView {...props} />)
  else
    return (<ChatMessagesView {...props} />)
}

function conversationKeyFromProps(props) {
  let key=0
  if (props.conversationKey)
    key = props.conversationKey
  else if (props.match)
    key = props.match.params.key
  return key
}

const mapStateToProps = (state, ownProps) => {
  let key = conversationKeyFromProps(ownProps)
  
  let c = null
  let isStarting = false
  
  if (key == 0) {
    c = getNewConversation(state)
    isStarting = state.alpha.conversationList.isStarting
  }
  else if (key == -1) {
    c = getLatestConversation(state)
    if (!c) {
      c = getNewConversation(state)      
      isStarting = state.alpha.conversationList.isStarting
    }
  }
  else
    c = getConversation(state, key)
  
  return {    
    conversationKey: key,
    conversation: c,
    iStarting: isStarting,
    business: getBusinessInfo(state, '0').data,
    user: getUser(state),
    wsState: state.alpha.conversationList.wsState
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  let key = conversationKeyFromProps(ownProps)

  return {
    startNewConversation: () => {
      dispatch(startNewConversation())
    },
    deleteConversationIfEmpty: () => {
      dispatch(deleteConversationIfEmpty(key))
    },
    wsStart: () => {
      dispatch(wsSend('start', null))
    },
    fetchMessages: () => {
      dispatch(fetchMessages(key, true, false))
    },
    sendMessage: (message, files=null) => {
      dispatch(sendMessage(key, message, files))
    },
    markReadAll: () => {
      dispatch(markReadAll(key))
    },
    startTyping: () => {
      dispatch(startTyping(key))
    },
    fetchContactInfo: () => { dispatch(fetchContactInfo()) },
    updateContactInfo: (data) => { dispatch(updateContactInfo(data)) },
  }
}

export default withResizeDetector(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatView)))
