import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { appReducer } from './data/reducer'
import { conversationMiddleware } from 'alpha-js'
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import App from './App'

import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'

import * as serviceWorker from './serviceWorker'

const store = createStore(
  appReducer,
  applyMiddleware(thunk, conversationMiddleware, logger)
  );

ReactDOM.render(
  <Provider store={store}>
  <ThemeProvider theme={theme}>
    <Router>  
      <App />
    </Router>
  </ThemeProvider>
  </Provider>   
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
