import { combineReducers } from 'redux'

import userReducer from './reducers/user'
import visitorReducer from './reducers/visitor'
import businessByIdReducer from './reducers/business'
import contentModelReducer from './reducers/content'
import conversationListReducer from './reducers/conversation'

export const alphaReducer = combineReducers({
  business: businessByIdReducer,
  user: userReducer,
  visitor: visitorReducer,
  content: contentModelReducer,
  conversationList: conversationListReducer,
})
