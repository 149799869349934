import React, { useState, useEffect, useContext, useRef } from 'react'

import { Footer as AlphaFooter } from 'alpha-page'

import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const styles = theme => ({
  poweredBy: {
    color: '#aaa',
    fontSize: 12,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
})

const Footer = ({ classes, ...props }) => {

  let showPoweredBy = true

  if (process.env.REACT_APP_HIDE_POWERED_BY)
    showPoweredBy = false  

  return (
    <>
      <AlphaFooter {...props}/>
      {showPoweredBy &&
        <Box display='flex' width='100%' flexDirection='column' alignItems='center'>
          <Typography variant='body2' className={classes.poweredBy}>
            <Link href="https://www.goapp.co.id" color="inherit" target="_blank">
            Built with GOAPP
            </Link> 
          </Typography>
        </Box>
      } 
    </>
    )
}

export default withStyles(styles)(Footer)