import React, { Component, useState, useEffect, useContext } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import { connect } from 'react-redux'
import { Route, Link, withRouter, BrowserRouter as Router, Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import ReactGA from  'react-ga'
import ReactPixel from 'react-facebook-pixel'

import { getBusinessInfo, fetchBusinessInfo } from 'alpha-js'

import { withAppBase } from 'alpha-js'
import { NavigationController, NavigationContext } from 'alpha-page'

import { BlogPage, BlogPostPage, Header, NotFound } from 'alpha-page'

import Page from './pages/Page'
import Footer from './pages/Footer'

const styles = theme => ({
  root: {
//    width: '100%',
//    display: 'flex',
//    justifyContent: 'center',
  },
})

const App = ({ params, theme, classes, history, business, showChat }) => {

  let smUp = useMediaQuery(theme.breakpoints.up('sm'))
  
  const navigationContext = useContext(NavigationContext)
  
  useEffect(() => {
    if (process.env.REACT_APP_GA_TRACKING_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
      // Send the first page
      ReactGA.pageview(history.location.pathname)
      // Handle location changed to page view event
      history.listen(location => ReactGA.pageview(location.pathname))
    } 
    if (process.env.REACT_APP_FB_PIXEL_ID) {
      const advancedMatching = {}
      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      };
      ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, advancedMatching, options);
      
      ReactPixel.pageView(); // For tracking page view
    }
  }, [])  

  const openLink = (linkTo) => {
    if (linkTo == "chat") {
      showChat()
      return
    }

    let link = ""
    if (linkTo.startsWith("/"))
      link = linkTo
    else
      link = "/" + linkTo

    if (link == "/home")
      link = "/"

    history.push(link)
  }

  const renderPage = (pageComponent, editMode=false) => (props) => (  
    React.createElement(pageComponent, {
      ...props,
      editMode,
      openLink})
  )
  
  return (
    <NavigationController
      header={(
        <Header business={ business.data } openLink={ openLink } />
        )}
      footer={(
        <Footer business={ business.data } openLink={ openLink } />
        )}
      >
      <div className={classes.root}>
        <Switch>
          <Route exact path="/" render={renderPage(Page)}/>
          <Route path="/blog/post/:blogPostKey" render={renderPage(BlogPostPage)}/>
          <Route path="/blog/:categoryKey/post/:blogPostKey" render={renderPage(BlogPostPage)}/>
          <Route path="/blog/:categoryKey" render={renderPage(BlogPage)}/>        
          <Route path="/blog" render={renderPage(BlogPage)}/>          
          <Route exact path="/:pageKey/edit" render={renderPage(Page, true)}/>
          <Route exact path="/:pageKey" render={renderPage(Page)}/>          
          <Route component={NotFound}/>
        </Switch>
      </div>
    </NavigationController>      
  )
}

const mapStateToProps = (state, ownProps) => {
  let urlParams = new URLSearchParams(ownProps.location.search)

  return {
    params: urlParams,
    user: state.user,
    visitor: state.visitor,
    business: getBusinessInfo(state, '0'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
//  let callbackUrl = process.env.REACT_APP_PUBLIC_URL + ownProps.location.path
  let callbackUrl = window.location.href
//  alert(callbackUrl)
  
  return {
  }
}

export default withAppBase(
  withRouter(
    withStyles(styles, { withTheme: true })(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(App)
    )
  ), {
    showFloatingChatButton: process.env.REACT_APP_SHOW_FLOATING_CHAT === "0" ? false : true
  }
)
