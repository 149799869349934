import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Reveal from 'react-reveal/Zoom';

const useStyles = makeStyles(({ palette, spacing }) => {
  const radius = spacing(2.5);
  const size = spacing(6);
  const rightBgColor = palette.primary.main;
  const rightAltBgColor = palette.primary.secondary;
  // if you want the same as facebook messenger, use this color '#09f'
  return {
    avatar: {
      width: size,
      height: size,
      backgroundColor: '#ddd'
    },
    msg: {
      padding: spacing(1, 2),
      borderRadius: 4,
      marginBottom: 4,
      display: 'inline-block',
      wordBreak: 'break-word',
      fontFamily:
        // eslint-disable-next-line max-len
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    card: {
      marginBottom: 4
    },
    status: {
      padding: spacing(0, 2),
      fontSize: '.9em'
    },
    leftRow: {
      textAlign: 'left',
    },
    left: {
      borderTopRightRadius: radius,
      borderBottomRightRadius: radius,
      backgroundColor: palette.grey[100],
    },
    leftFirst: {
      borderTopLeftRadius: radius,
    },
    leftLast: {
      borderBottomLeftRadius: radius,
    },
    rightRow: {
      textAlign: 'right',
    },
    right: {
      borderTopLeftRadius: radius,
      borderBottomLeftRadius: radius,
      backgroundColor: rightBgColor,
      color: palette.common.white,
    },
    rightAlt: {
      backgroundColor: palette.grey[500],
    },
    rightFirst: {
      borderTopRightRadius: radius,
    },
    rightLast: {
      borderBottomRightRadius: radius,
    },
  };
});

const ChatMessage = ({ avatar, messages, cardComponents, side, alternate=false, status, animated }) => {
  const classes = useStyles();
  const attachClass = index => {
    if (index === 0) {
      return classes[`${side}First`];
    }
    if (index === messages.length - 1) {
      return classes[`${side}Last`];
    }
    return '';
  };
  const altClass = index => {
    if (alternate)
      return classes[`${side}Alt`];
    else
      return '';
  };
  return (
    <Reveal duration={animated ? 500 : 0}>
    <Grid
      container
      spacing={2}
      justify={side === 'right' ? 'flex-end' : 'flex-start'}
    >
      {true && side === 'left' && avatar && (
          <Grid item>
            <Avatar className={classes.avatar} src={avatar} />
          </Grid>
      )}
      <Grid item xs={9}>
        {messages.map((msg, i) => (
          <div className={classes[`${side}Row`]}>
            <Typography
              className={`${classes.msg} ${classes[side]} ${attachClass(i)} ${altClass(i)}`}
            >
              {msg}
            </Typography>
          </div>
        ))}
        { cardComponents &&
          <div>
            { cardComponents.map(card =>
            <div className={classes.card}>
              { card }
            </div>
              )
            }
          </div>
        }
        { status &&
          <div className={classes[`${side}Row`]}>        
            <Typography
              color="textSecondary"
              className={classes.status}
            >
              {status}
            </Typography>
          </div>
        }
      </Grid>
    </Grid>
    </Reveal>
  );
};

ChatMessage.propTypes = {
  avatar: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  side: PropTypes.oneOf(['left', 'right']),
};
ChatMessage.defaultProps = {
  avatar: '',
  messages: [],
  side: 'left',
  status: '',
};

export default ChatMessage;
