import React, { useState, useEffect, useContext, useRef } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchConversationList, setActiveConversation, WSSTATE } from '../data/reducers/conversation'
import { getBusinessInfo } from '../data/reducers/business'
import { withStyles } from '@material-ui/core/styles'

import Chat from './Chat'
import Inbox from './Inbox'

import useMediaQuery from '@material-ui/core/useMediaQuery'

// UI
import Fab from '@material-ui/core/Fab'
import Divider from '@material-ui/core/Divider'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Slide from '@material-ui/core/Slide'
import QuestionAnswerIcon from '@material-ui/icons/Forum'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import Badge from '@material-ui/core/Badge'


const styles = theme => ({
  root: {
  },
  floatingChatButton:{
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  windowFrame:{
    /*
    '&::after':{
      bottom: '85px',
      right: '20px',
      width: 0,
      height: 0,
      content: "''",
      borderTop: 'solid 20px white',
      borderLeft: 'solid 25px transparent',
      marginLeft: '0px',
      position: 'fixed',
      borderRight: 'solid 25px transparent',
    },
    */
    zIndex: 1250, // Above Drawer, below Modal
    position: 'fixed',
    bottom: '100px',
    right: '20px',
    width: '400px',
    minHeight: '250px',
    maxHeight: '704px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 5px 40px',
    opacity: 1,
    height: 'calc(100% - 120px)',
    borderRadius: '8px',
    overflowX: 'hidden',
    background: 'white',
    [theme.breakpoints.down('xs')]:{
      // paddingTop: theme.spacing(5),
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      width: '100%',
      minHeight: '100%',
      maxHeight: '100%',
      height: '100%',
      borderRadius: '0px',
      // overflowY: 'auto',
      background: 'white',
    }
  },
  headerChat:{
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  labelChat:{
    paddingLeft: theme.spacing(2)
  },
  header: {
    zIndex: 1301,
    position: 'sticky',
    top: '0',
  },  
  iconClose:{
    display: 'block',
    cursor: 'pointer',
    position: 'absolute',
    right: '16px',
    top: '26px',
    color: '#aaa'
  },
  iconBack:{
    display: 'block',
    cursor: 'pointer',
    position: 'absolute',
    left: '16px',
    top: '26px',
    color: '#aaa'
  },
  noDecoration:{
    textDecoration: 'none'
  }  
})
  
export const ChatWindow = ({ theme, classes, history, business, wsState, activeConversationKey, setActiveConversation, fetchConversationList, isFetching, lastFetched, conversations, stats, showFloatingChatButton, startChatOnEmpty=true, isOpen, onShow, onHide }) => {

  const [conversationKey, setConversationKey] = useState(startChatOnEmpty ? -2 : -1)

  const topDiv = useRef()

  // This will be called on component init  
  useEffect(() => {
    fetchConversationList()

    return () => {
    }        
  }, [])
  
  /*
  useEffect(() => {
    if (conversationKey != activeConversationKey)
      setConversationKey(activeConversationKey)
  }, [activeConversationKey])
  */
  
  useEffect(() => {
    if (conversationKey == -1 && topDiv.current)
      // Scroll to top
      topDiv.current.scrollIntoView()
      
  }, [conversationKey])
  
  useEffect(() => {
    // Auto start new chat
    if (conversationKey == -2 && lastFetched && isOpen && wsState == WSSTATE.Connected && conversations.length <= 0)
      setConversationKey(0)
  }, [conversationKey, lastFetched, isOpen, wsState])
  
  let smUp = useMediaQuery(theme.breakpoints.up('sm'))
  
  let windowStyle = {}
  if (smUp) {
    if (showFloatingChatButton) {
      windowStyle = {
        top: 'auto',
        bottom: '100px'
      }
    }
    else {
      windowStyle = {
        top: '100px',
        bottom: 'auto'
      }
    }
  }
  
  let chatIcon = (
    <QuestionAnswerIcon />
    )
  if (stats && stats.new_count && stats.new_count > 0)
    chatIcon = (
      <Badge badgeContent={ stats.new_count } color="secondary">
        { chatIcon }
      </Badge>
    )
  
  const showConversation = (key) => {
    if (wsState != WSSTATE.Connected) {
      alert("Can not start chat while you are not online. Please check your internet connection.")
      return
    }
    /*
    if (key != activeConversationKey)
      setActiveConversation(key)
    else
      setConversationKey(key)
    */
    
    setConversationKey(key)
  }
  
  const hideConversation = () => {
    setConversationKey(-1)
  }
  
  return (
    <div className={classes.root}>
      { showFloatingChatButton &&
      <div className={classes.floatingChatButton}>
        { isOpen ?
          <Fab color="primary" aria-label="add" onClick={onHide}>
            <CloseIcon/>
          </Fab>
        :
          <Fab color="primary" aria-label="add" onClick={onShow}>
            { chatIcon }
          </Fab>
        }
      </div>
      }
      
      { isOpen &&
      <ClickAwayListener>
        <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
          <div className={classes.windowFrame} style={windowStyle}>
            <div className={classes.header}>
              <div className={classes.iconClose} onClick={onHide}>
                <CloseIcon style={{fontSize:'2rem'}}/>
              </div>
              { conversationKey >= 0 &&
                <div className={classes.iconBack} onClick={hideConversation}>
                  <ArrowBackIcon style={{fontSize:'2rem'}}/>
                </div>
              }
            </div>
            <div ref={topDiv}/>
            
            { conversationKey >= 0 ?
              <Chat 
                conversationKey={conversationKey} 
                openChat={showConversation} 
                closeChat={hideConversation}
                onHide={onHide}
                history={history}
              />
            :   
              <Inbox
                openChat={showConversation}
                onHide={onHide}
              />
            }            
          </div>
        </Slide>
      </ClickAwayListener>      
      }
    </div>
    )
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.alpha.conversationList,
    business: getBusinessInfo(state).data    
  }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchConversationList: () => {
      dispatch(fetchConversationList(true))
    },
    setActiveConversation: (key) => {
      dispatch(setActiveConversation(key))
    }
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatWindow)))