import ls from 'local-storage'

import React, { useState, useEffect, useContext } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

// Data
import { getVisitor, init, register, logEvent } from './data/reducers/visitor'
import { login, getUser, getCompanies, authenticateWithToken, fetchContactInfo, setActiveCompany } from './data/reducers/user'
import { getBusinessInfo, fetchBusinessInfo } from './data/reducers/business'
import { fetchConversationList, WSSTATE } from './data/reducers/conversation'

import { wsConnect } from './middleware/conversation'

import ChatWindow from './chat/ChatWindow'

const styles = theme => ({
  root: {
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  floatingChat:{
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    zIndex: 2
  },
  frameChat:{
    '&::after':{
      bottom: '85px',
      right: '20px',
      width: 0,
      height: 0,
      content: "''",
      borderTop: 'solid 20px white',
      borderLeft: 'solid 25px transparent',
      marginLeft: '0px',
      position: 'fixed',
      borderRight: 'solid 25px transparent',
    },
    zIndex: '1000',
    position: 'fixed',
    bottom: '100px',
    right: '20px',
    width: '376px',
    minHeight: '250px',
    maxHeight: '704px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 5px 40px',
    opacity: 1,
    height: 'calc(100% - 120px)',
    borderRadius: '8px',
    overflowX: 'hidden',
    background: 'white',
    [theme.breakpoints.down('xs')]:{
      paddingTop: theme.spacing(5),
      zIndex: '1000',
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      width: '100%',
      minHeight: '100%',
      maxHeight: '100%',
      height: '100%',
      borderRadius: '0px',
      // overflowY: 'auto',
      background: 'white',
    }
  },
  headerChat:{
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  labelChat:{
    paddingLeft: theme.spacing(2)
  },
  iconClose:{
    display: 'none',
    [theme.breakpoints.down('xs')]:{
      display: 'block',
      position: 'fixed',
      right: '10px',
      top: '10px'
    }
  },
  noDecoration:{
    textDecoration: 'none'
  }
})

const AppBase = (props) => {
  const { classes, business, history, user, companies, visitor, wsState, params } = props
  const { showFloatingChatButton=true, startChatOnEmpty=true } = props
  
  const { fetchBusinessInfo, fetchConversationList, fetchProductsCount, login, authenticateWithToken, setActiveCompany, fetchContactInfo, init, register } = props

  const { 
    apiKey=process.env.REACT_APP_GOAPP_API_KEY, 
    apiUrl=process.env.REACT_APP_GOAPP_API_URL 
  } = props
  
  const getWSHost = () => {    
    let host = apiUrl + "/ws/conversation/"      
    host = host.replace("http://", "ws://")
    host = host.replace("https://", "wss://")
    host += "?"
    if (visitor.data.visitor_id)
      host += "&visitor=" + visitor.data.visitor_id
    if (user.isLoggedIn)
      host += "&token=" + user.authToken
    host += "&api_key=" + apiKey
    return host
  }
  
  const [isOnline, setIsOnline] = useState(false)

  // Add support for staff app with special API Key
  // For staff app we fetch business info after user login
  const isStaffApp = apiKey == "000000000000"
  
  useEffect(() => {
    if (!isStaffApp)
      fetchBusinessInfo()
    
    window.addEventListener('online', (e) => {
      setIsOnline(true)
    })
    
    window.addEventListener('offline', (e) => {
      setIsOnline(false)
    })
    
    return () => {
//      clearInterval(conversationTimerID)
    }
  }, [])
  
  useEffect(() => {
//    alert("WebSocket state: " + wsState)
    if (wsState == WSSTATE.Disconnected) {
      let timeout = 250
      if (!isOnline)
        timeout = 3000
    
      setTimeout(() => {
//        alert(getWSHost())
        props.wsConnect(getWSHost())
      }, timeout)
    }
    else if (wsState == WSSTATE.Connected) {
      // Refresh conversation list on re-connect.
      fetchConversationList(true)      
    }
  }, [wsState])
  
  // Floating chat state
  const [isChatWindowOpen, setChatWindowOpen] = useState(false)
  const showChatWindow = (show) => {
    setChatWindowOpen(show)
  }  

  useEffect(() => {  
    if (!business.lastFetched && !isStaffApp)
      // Wait
      return

    if (params.get('reset') == 1) {
      ls.remove('_goid')
      ls.remove('_userInfo')
      // Reload app
      window.open(process.env.REACT_APP_PUBLIC_URL, "_self")
      return
    }
    
    // Check token from callback URL
    let authToken = params.get('auth_token')
    
    if (authToken && !user.isLoggedIn) {
      let authSuccess = params.get('auth_success')
      authenticateWithToken(authToken)
      
      params.delete('auth_token')
      params.delete('auth_success')
      
      // Update url
      // Note: Should use history.replace so that path with token not pushed
      // to history, but it doesn't work.
      history.push({ pathname: history.location.pathname, search: params.toString()})
    }
    else if (user.isAuthenticating) {
      // Just wait
    }
    else if (user.userInfo && !user.isLoggedIn) {
      if (!user.isAuthenticating)
        // Try to login automatically
        login()
    }
    else if (isStaffApp) {
      if (user.isLoggedIn) {
        if (!business.lastFetched)
          fetchBusinessInfo()
        else {
          if (wsState == WSSTATE.Idle)
            props.wsConnect(getWSHost())
        }
      }
      else {
        // Wait for login
      }
    }
    else if (!visitor.isInitialized) {
      init()
    }
    else if (!visitor.isRegistered) {
      let registerData = {}
      if (user.isLoggedIn)
        registerData = {
          external_app: 'alpha.apps.directory',
          external_model: 'user',
          external_id: user.userInfo.uid,
          first_name: user.userInfo.first_name,
          last_name: user.userInfo.last_name,
          email: user.userInfo.email,
          mobile_no: user.userInfo.mobile_no
        }      
      else 
        registerData =  {
        }
        
      register(registerData)
    }
    else if (user.isUpdatingContact) {
      // Just wait
    }
    else if (!user.lastContactUpdate) {
      // Fetch contact info.
      fetchContactInfo()
    }
    else {
      /*
      if (!conversationTimerID || conversationTimerID == undefined) {
        // First chat
        fetchConversationList(true)
        
        let conversationTimerID = setInterval(() => {
  //          alert("timer timeout")
          fetchConversationList(true)
          }, 15000)
        setConversationTimerID(conversationTimerID)
      }
      */
      
      // Connect to conversation ws
      if (wsState == WSSTATE.Idle) {
        props.wsConnect(getWSHost())
      }
      
/*
      if (!hasFetchProductsCount) {
        fetchProductsCount()
        setHasFetchProductsCount(true)
      }      
*/
    
      if (user.isLoggedIn && !user.companyInfo && companies.length > 0)
        setActiveCompany(companies[0].uid)
    }
    
  
//    alert(this.props.location)
//    if (this.props.location !== prevProps.location) {
//      visitor_log_event('View Page', { 'url': this.props.location })
//    }
  })
  
  // Show page
  if (isStaffApp) {
    if (user.isAuthenticating || business.isFetching) {
      let message = "..."
      if (business.isFetching)
        message = "One second..."
      else if (user.isAuthenticating)
        message = "Authenticating..."
      
      return (
        <div className={classes.root}>
          <div className={classes.wrapper}>
          { message }
          </div>
        </div>
      )
    }
    else {
      const { mainComponent, mainProps } = props
      
      let component = React.createElement(mainComponent, {
        ...mainProps,
        showChat: () => setChatWindowOpen(true)
        })

      return (  
        <div className={classes.root}>
          { component }
        </div>
      )
    }
  }
  else {
    if (business.lastFetched && !user.isAuthenticating && visitor.isRegistered && user.lastContactUpdate ) {
    
      const { mainComponent, mainProps } = props
      
      let component = React.createElement(mainComponent, {
        ...mainProps,
        showChat: () => setChatWindowOpen(true)
        })
    
      return (  
        <div className={classes.root}>
          { component }
          <ChatWindow 
            showFloatingChatButton={showFloatingChatButton} 
            startChatOnEmpty={startChatOnEmpty}
            isOpen={isChatWindowOpen} 
            onHide={()=>showChatWindow(false)} 
            onShow={()=>showChatWindow(true)}
            />
        </div>
      )
    }
    else {
      // Show loading messages
      
      let message = "..."
      if (business.isFetching)
        message = "Initializing..."
      else if (user.isAuthenticating)
        message = "Authenticating..."
      else if (visitor.isInitializing || visitor.isRegistering)
        message = "Almost done..."
      else if (user.isUpdatingContact)
        message = "One second..."
      
      return (
        <div className={classes.root}>
          <div className={classes.wrapper}>
          { message }
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let urlParams = new URLSearchParams(ownProps.location.search)
  
  return {
    user: getUser(state),
    companies: getCompanies(state),
    visitor: state.alpha.visitor,
    business: getBusinessInfo(state, '0'),
    params: urlParams,
    wsState: state.alpha.conversationList.wsState,
    chat: false
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
//  let callbackUrl = process.env.REACT_APP_PUBLIC_URL + ownProps.location.path
  let callbackUrl = window.location.href
//  alert(callbackUrl)
  
  return {
    fetchBusinessInfo: () => {
      dispatch(fetchBusinessInfo('0'))
    },
    login: () => {
      dispatch(login(callbackUrl, true))
    },
    authenticateWithToken: (authToken) => {
      dispatch(authenticateWithToken(authToken))
    },
    fetchContactInfo: () => {
      dispatch(fetchContactInfo())
    },
    setActiveCompany: (companyID) => {
      dispatch(setActiveCompany(companyID))
    },
    init: () => {
      dispatch(init())
    },
    register: (registerData) => {
      dispatch(register(registerData))
    },
    fetchProductsCount: () => {
      dispatch(fetchProductIndex('default', null))
    },
    fetchConversationList: (refresh) => {
      dispatch(fetchConversationList(refresh))
    },
    wsConnect: (host) => {
      dispatch(wsConnect(host))
    }
  }
}

const App = withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AppBase)
  )
)

export const withAppBase = (component, params={}) => (props) => {
  return (
    <App
      mainComponent={component}
      mainProps={props}
      {...params}
      />
  )
}

export default App